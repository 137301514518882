import * as React from 'react'
import { useState } from 'react';
import { useForm } from "react-hook-form";
import {callHook} from '../../utils/utils';
var validator = require("email-validator");
import settings from '../../settings.json';

export const QRWiFi = ({ gclid, msclkid }) =>  {
  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const [isEmailVisible, setIsEmailVisible] = useState(false);

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });

  const onShortenClick = () => {
	  setIsEmailVisible(!isEmailVisible);
	};

  const wifiData = "WIFI:S:${ssid};T:${encryption};P:${pass};H:${hidden};;"

  const onSubmit = async (data) => {
    //console.log('data: ', data);
    let url = settings.n8n_qr_gen;
    let mode = "no-cors";

    let qrData = {type: "Wifi", email: data.email.trim(), data: "", gclid: gclid, msclkid: msclkid}
    
    qrData.data = "WIFI:S:"+data.ssid+";T:"+data.encryption+";P:"+data.pass+";H:"+data.hidden+";;"

    //console.log('qrData: ', qrData);
    const r = await callHook({url, uid:'yohn-qr-code', params:qrData, mode});
    //console.log(r);

    setSend(true);
  }

  if (isSend) {
    return (
      <div className={"py-5"} id="try">
		    <div className={"container"}>
          <div className="text-center col-12 col-lg-8 mx-auto">
            {!isError && (
            <>
              <h3>Thank You!</h3>
              <h3>We have sent the QR code to your e-mail address.</h3>
            </>
            )}
            {isError && (
            <>
              <h3>An error has occurred</h3>
              <p>Please try again later, server is not available now.</p>
            </>
            )}
            <div className="col-auto mt-4">
              <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{isError ? "Try again" : "Generate another"}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="col-12 col-md-9 col-lg-7 mx-auto row align-items-center">
      <form id="wifi" className="text-center col" onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <input
            placeholder="Network Name"
            className={'form-control' + " " + (errors.full && "is-invalid")}
            {...register('ssid', {required: true})}
          />
        </div>
        <div className="mt-4">
          <input
            placeholder="Password"
            className={'form-control' + " " + (errors.full && "is-invalid")}
            {...register('pass', {required: true})}
          />
        </div>
        <div className="mt-4 row">
          <div className="col-auto me-3">
            <p>Encryption</p>
          </div>
          <div className="col-auto">
            <div className="row gy-3 mx-auto">
              <div className="form-check col-auto">
                <input
                  className="form-check-input"
                  type="radio"
                  name="encryption"
                  id="encryption-none"
                  value="nopass"
                  defaultChecked
                  {...register('encryption', {required: true})}
                />
                <label className="form-check-label" for="encryption-none">None</label>
              </div>
              <div className="form-check col-auto">
                <input
                  className="form-check-input"
                  type="radio"
                  name="encryption"
                  id="encryption-wpa"
                  value="WPA"
                  {...register('encryption', {required: true})}
                />
                <label className="form-check-label" for="encryption-wpa">WPA/WPA2</label>
              </div>
              <div className="form-check col-auto">
                <input
                  className="form-check-input"
                  type="radio"
                  name="encryption"
                  id="encryption-wep"
                  value="WEP"
                  {...register('encryption', {required: true})}
                />
                <label className="form-check-label" for="encryption-wep">WEP</label>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 text-start">
          <label className="form-check-label" for="hidden"><span className="">Hidden</span><input className="form-check-input ms-2" type="checkbox" value="" id="hidden" {...register('hidden')}/></label>
        </div>
        <div className={"mt-4" + " " + (!isEmailVisible && "d-none")}>
          <input
            placeholder="Your email address"
            className={'form-control' + " " + (errors.email && isSubmitted ? "is-invalid" : "")}
            {...register('email', {required: true, validate: (input) => validator.validate(input)})}
          />
          {errors.email && isSubmitted && <div className="invalid-feedback">{'Invalid email address'}</div>}
          <div className="text-center mt-3 fs-0875">Type in or paste your email address here and we will send your QR-code to you right away.</div>
        </div>
        <div className={"d-flex justify-content-center mt-4" + " " + (!isEmailVisible ? "d-none" : "")}>
          <label className="form-check-label" for="flexCheckChecked"><input className="form-check-input me-2" type="checkbox" value="" id="flexCheckChecked" required/><span className="fs-0875">I agree to the <a href="/terms/">Terms of Service</a>, <a href="/privacy-policy/">Privacy Policy</a> and Use of Cookies.</span></label>
        </div>
        <input type="submit" value="Get QR code" className={"mt-4 btn btn-sm btn-primary text-uppercase" + " " + (!isEmailVisible && "d-none")}/>
      </form>
      <div className={"text-center col-12 mt-4" + " " + (isEmailVisible ? "d-none" : "")}>
        <button onClick={onShortenClick} className={"btn btn-sm btn-primary align-center text-uppercase"}>Get QR code</button>
      </div>
    </div>
  );
}