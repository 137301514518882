import * as React from 'react';
import { useState } from 'react';
import { graphql } from 'gatsby';
import { useForm } from "react-hook-form";
import { callHook } from '../utils/utils';

export const BusinessCardReader = ({ slice, index }) => {
  const { title } = slice.primary;

  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("")
  const [fileUrl, setFileUrl] = useState("")
  const [parsedData, setParsedData] = useState("")
  const [tesseractText, setSesseractText] = useState("")

  const { register, handleSubmit, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });

  const isFirst = index === 0;

  if (typeof window !== 'undefined') {
    const pageUrl = window.location;
    //console.log('pageUrl: ', pageUrl);
  }

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('file', data.file[0]);

    setLoading(true);
    try {
      const response = await fetch('https://n8n.yohn.io/webhook/bcscan', {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log('responseData: ', responseData);
        if (responseData && responseData.img) {
          setImgUrl(responseData.img);
          setFileUrl(URL.createObjectURL(new Blob([responseData.vcf], { type: 'text/x-vcard;charset=utf-8' })))
          setParsedData(responseData.parsed_data)
          setSesseractText(responseData.tesseract_text)
          setError(false);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
      alert(1)
    }

    setSend(true);
    setLoading(false);
  };

  if (isLoading && !isSend) {
    return (
      <div className="py-6 bg-color-1">
			  <div className="container">
          {isFirst ? <h1 className="h2 text-center mb-5">{title}</h1> : <h2 className="text-center mb-5">{title}</h2>}
          <div className="text-center">
            <div className="loader mx-auto"></div>
          </div>
        </div>
      </div>
    );
  }

  if (isSend) {
    return (
      <div className="py-6 bg-color-1">
			  <div className="container">
          {isFirst ? <h1 className="h2 text-center mb-5">{title}</h1> : <h2 className="text-center mb-5">{title}</h2>}
          <div className="text-center">
            {!isError ?
              <div className="text-center">
                <img src={imgUrl} alt="111" width="160" height="160"/>
                <div className="mt-4">
                  <a href={fileUrl} download="business-card.vcf">Download VCF</a>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <ul className="list-group mt-4" style={{width:"fit-content"}}>
                    {Object.entries(parsedData).map(([key, value]) => (
                      <li key={key} className="text-start list-group-item">
                        <strong>{key}:</strong> {value}
                      </li>
                    ))}
                  </ul>
                  <p className="mt-3 list-group list-group-item">{tesseractText}</p>
                </div>
              </div>
            :
              <div className="text-center">
                <h3>An error has occurred</h3>
                <p>Please try again later.</p>
              </div>
            }
            <div className="mt-4">
              <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{"Try again"}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="py-6 bg-color-1">
			<div className="container">
        {isFirst ? <h1 className="h2 text-center mb-5">{title}</h1> : <h2 className="text-center mb-5">{title}</h2>}
        <div className="col-12 col-md-9 col-lg-7 mx-auto">
          <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <input type="file" id="file" {...register('file', { required: true })} className="form-control" />
              {errors.file && <p>File is required.</p>}
            </div>
            <button type="submit" className={"mt-4 btn btn-sm btn-primary text-uppercase"}>Submit</button>
          </form>
        </div>
			</div>
		</div>
  )
};

export const query = graphql`
  fragment PageDataBodyBusinessCardReader on PrismicPageDataBodyBusinessCardReader {
    primary {
      title
    }
  }
`;
