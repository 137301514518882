import * as React from 'react'
import { graphql } from 'gatsby'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { PrismicRichText } from '@prismicio/react'
import {callHook} from '../utils/utils';
var validator = require('email-validator')
import settings from '../settings.json'

export const BusinessCardForm = ({ slice, index }) => {
  const [isBtnDisabled, setBtnDisabled] = useState(false)
  const [isSend, setSend] = useState(false)
  const [isError, setError] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [passkitId, setPasskitId] = useState("");

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'onChange',
  })

  const {
    title,
    text_premium,
    input_label_first_name,
    input_label_last_name,
    input_label_email,
    input_label_phone,
    text_agreement,
    text_test,
    text_price_1,
    text_price_2,
    text_price_3,
    text_free_for_7_days,
    text_just_a_sec,
    text_we_are_generating,
    text_card_ready,
    title_error,
    text_error,
    btn_label_generate,
    btn_label_buy,
    btn_label_download,
    btn_label_try_again,
    text_mockup_left,
    text_mockup_right,
    text_muckup_center,
    title_how_dbc_work,
    text_how_dbc_work_1,
    text_how_dbc_work_2,
    text_how_dbc_work_3,
    img,
    img2,
    img_google_wallet,
    img_apple_wallet
  } = slice.primary

  let gclid = ""
  let msclkid = ""
  if (typeof window !== 'undefined'){
    const pageUrl = new URL(window.location);
    gclid = pageUrl.searchParams.get("gclid");
    //console.log('gclid: ', gclid);
    msclkid = pageUrl.searchParams.get("msclkid");
    //console.log('msclkid: ', msclkid);
  }

  const onSubmit = async (data) => {
    setBtnDisabled(true);
    console.log('data: ', data);
    const url = "https://n8n.yohn.io/webhook/free-business-card"
    const mode = "no-cors"
    let qrData = {type: "FreeBusinessCard", email: data.email, data: "", gclid: gclid, msclkid: msclkid}

    const person = {
      "surname": data.lastName,
      "forename": data.firstName,
      "emailAddress": data.email,
      "mobileNumber": data.phone
    };

    const text = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${data.lastName};${data.firstName}`,
      `FN:${data.firstName} ${data.lastName}`,
    ];

    if (data.email) {
      text.push(`EMAIL;TYPE=INTERNET:${data.email}`);
    }
    if (data.phone) {
      text.push(`TEL;CELL:${data.phone}`);
    }
    text.push("END:VCARD")

    const qr_pass = {
      "person": person,
      "vcard": text.join("\r\n")
    }

    qrData.data = text.join('\r\n');

    console.log('qr_pass: ', qr_pass);

    qrData.qrPass = qr_pass;
    window.scrollTo(0, 0)
    setLoading(true);
    const r = await callHook({url, uid:'yohn-qr-code', params:qrData, mode});
    console.log('callHook r: ', r);
    if (r && r.success === true) {
      setError(false);
      setSend(true);
      setLoading(false);
      setBtnDisabled(false);
      setToken(r.userToken)
      setPasskitId(r.passkitId)
    } else {
      setError(true);
    }
  }

  useEffect(() => {
    const before = document.getElementById('card-before');
    const after = document.getElementById('card-after');

    setTimeout(function(){
    before.style.opacity = isSend ? 0 : 1;
    before.style.transform = `translate(${isSend ? 100 :0}px, 0px)`;
    }, isSend ? 0 : 250)

    setTimeout(function(){
      after.style.opacity = isSend ? 1 : 0;
      after.style.transform = `translate(${isSend ? 0 : -100}px, 0px)`;
    }, isSend ? 250 : 0)

  }, [isSend]);

  const PremiumCTA = () => (
    <div className="bg-primary" style={isSend ? {background: "linear-gradient(152deg, rgba(50,37,37,1) 0%, rgba(11,5,5,1) 100%)"} : {} }>
      <div className="container py-5 px-0 px-lg-5">
        <div className="row justify-content-between g-3">
          <h3 className={`col-12 col-lg-8 text-center text-lg-start align-middle ${isSend ? `text-white` : ``}`}>{text_premium}</h3>
          <div className="col-12 col-lg-3 text-center me-0 me-lg-5">
            <a href={`https://buy.stripe.com/7sIeXvckV4mHaJ2cMY?token=${token}`} className={`btn btn-light text-uppercase`} style={isSend ? {background: "linear-gradient(152deg, rgba(242,142,38,1) 0%, rgba(253,100,79,1) 100%)", color:"#fff", borderColor:"transparent"} : {} }>{btn_label_buy}</a>
            <p className={`mt-2 mb-0 h6 ${isSend ? `text-white` : ``}`} style={{fontSize:"14px"}}>{text_price_1} <span style={{textDecorationLine:"line-through",textDecorationThickness:"0.1rem"}}>{text_price_2}</span> {text_price_3}</p>
          </div>
        </div>
      </div>
    </div>
  )

  const HowItWorks = () => (
    <div className="bg-white" id="how-do-digital-business-cards-work" >
      <div className="container pt-6">
        <h2 className={`text-center mb-5`}>{title_how_dbc_work}</h2>
        <div className="row g-5">
          <div className="col-12 col-lg-4 text-center">
            <div className="">
              <img id="card-after" src={"https://images.prismic.io/colibri-live/ZtHQfkaF0TcGJl3h_how-cards-work-1.png?auto=format,compress"} alt="" className="img-fluid img-shadow" style={{maxHeight:"480px"}} loading="lazy"/>
              <h5 class="mt-4">{text_how_dbc_work_1}</h5>
            </div>
          </div>
          <div className="col-12 col-lg-4 text-center">
            <div className="" style={{maxHeight:"600px"}}>
              <img id="card-after" src={"https://images.prismic.io/colibri-live/ZqkcFx5LeNNTxmt6_how-cards-work-2.png?auto=format,compress"} alt="" className="img-fluid img-shadow" style={{maxHeight:"480px"}} loading="lazy"/>
              <h5 class="mt-4">{text_how_dbc_work_2}</h5>
            </div>
          </div>
          <div className="col-12 col-lg-4 text-center">
            <div className="" style={{maxHeight:"600px"}}>
              <img id="card-after" src={"https://images.prismic.io/colibri-live/ZtHQfkaF0TcGJl3g_how-cards-work-3.png?auto=format,compress"} alt="" className="img-fluid img-shadow" style={{maxHeight:"480px"}} loading="lazy"/>
              <h5 className="mt-4">{text_how_dbc_work_3}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div className={'py-6'}>
        <div className={`container ${isSend ? "py-lg-5" : ""}`}>
          <div className="row g-5">
            {isError ?
              <div className="col d-flex flex-column justify-content-center align-items-center text-center">
                <h3>{title_error}</h3>
                <p>{text_error}</p>
                <button onClick={()=>{setError(false), setLoading(false), setBtnDisabled(false)}} className={"btn btn-sm text-uppercase btn-primary w-auto"}>{btn_label_try_again}</button>
              </div>
            :
              <>
                {isLoading ?
                  <div className="col d-flex flex-column justify-content-center align-items-center text-center">
                    <div className="loader mx-auto"></div>
                    <h3>{text_just_a_sec}</h3>
                    <p>{text_we_are_generating}</p>
                  </div>
                    :
                  <div className="col pt-lg-5">
                    {isSend ? 
                      <p className="h3 text-center mb-3 mt-lg-5">{text_card_ready}</p>
                    :
                      <h1 className="h2 mb-0 text-center" id="title">{title}</h1>
                    }
                    {!isSend ? 
                      <div className="mt-5">
                        <form
                          id="freebusinesscard"
                          className="text-center col"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="row g-3">
                            <div className={'position-relative d-flex col-12 col-md-6'}>
                              <input
                                placeholder={`${input_label_first_name} *`}
                                className={'form-control' + ' ' + (errors.full && 'is-invalid')}
                                {...register('firstName', { required: true })}
                              />
                            </div>
                            <div className={'position-relative d-flex col-12 col-md-6'}>
                              <input
                                placeholder={`${input_label_last_name} *`}
                                className={'form-control' + ' ' + (errors.full && 'is-invalid')}
                                {...register('lastName', { required: true })}
                              />
                            </div>
                            <div className={'position-relative d-flex flex-column col-12 col-md-6'}>
                              <input
                                placeholder={`${input_label_email} *`}
                                className={'form-control' + " " + (errors.email && isSubmitted ? "is-invalid" : "")}
                                {...register('email', {required: true, validate: (input) => validator.validate(input)})}
                              />
                              {errors.email && isSubmitted && <div className="invalid-feedback">{'Invalid email address'}</div>}
                            </div>
                            <div className={'position-relative d-flex col-12 col-md-6'} style={{height:"fit-content"}}>
                              <input
                                placeholder={`${input_label_phone}`}
                                className={'form-control' + ' ' + (errors.full && 'is-invalid')}
                                {...register('phone', { required: false })}
                              />
                            </div>
                          </div>
                          <div className={'d-flex justify-content-center mt-4'}>
                            <label className="form-check-label d-flex" for="flexCheckChecked">
                              <input
                                className="form-check-input me-2"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                required
                              />
                              <span className="fs-0875">
                                <PrismicRichText field={text_agreement.richText} />
                              </span>
                            </label>
                          </div>
                          <input
                            type="submit"
                            value={btn_label_generate}
                            className={'mt-4 btn btn-sm btn-primary text-uppercase'}
                            disabled={isBtnDisabled}
                          />
                        </form>
                        <p className="mt-3 mb-0 h6 text-center" style={{fontSize:"14px"}}>{text_test}</p>
                      </div>
                    :
                      <div className="text-center mx-auto">
                        <div className="col-auto mt-4">
                          <div className="d-flex flex-column justify-content-center gap-3 gap-xl-4">
                            <div className="">
                              {/*<a href={`https://pub1.pskt.io/${passkitId}`} className={`btn text-uppercase btn-primary mb-2 ${btn_label_download.length > 24 ? `btn-sm mt-lg-1` : ``}`}>{btn_label_download}</a>*/}
                              <div className="mt-1 mb-2">
                                <a href={`https://pub1.pskt.io/${passkitId}`}>
                                  <div className="row flex-wrap justify-content-center gx-3 gy-2">
                                    <div className="col-auto">
                                      <img src={img_google_wallet.url} height="54" alt={img_google_wallet.alt}/>
                                    </div>
                                    <div className="col-auto">
                                      <img src={img_apple_wallet.url} height="54" alt={img_apple_wallet.alt}/>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <p className="h6">{text_test}</p>
                            </div>
                            <div className="">
                              <a href={`https://buy.stripe.com/7sIeXvckV4mHaJ2cMY?token=${token}`} className={"btn text-uppercase btn-primary mb-2"} style={{background: "linear-gradient(152deg, rgba(242,142,38,1) 0%, rgba(253,100,79,1) 100%)", color:"#fff",  borderColor:"transparent"}}>{btn_label_buy}</a><br/>
                              <p className="h6">{text_price_1} <span style={{textDecorationLine:"line-through",textDecorationThickness:"0.1rem"}}>{text_price_2}</span> {text_price_3}</p>
                            </div>
                          </div>
                        </div>
                        <img src={img2.url} alt={img2.alt} className="d-lg-none img-fluid img-shadow mt-4" style={{maxHeight:"600px"}} loading="lazy"/>
                        <div className="d-lg-none">
                          <div className="text-center d-flex justify-content-around mt-4 gap-3">
                            <p className="h4">{text_mockup_left}</p>
                            <p className="h4">{text_mockup_right}</p>
                          </div>
                          <div className="text-center mt-2">
                            <p className="h5 mb-0">{text_muckup_center}</p>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                }
              </>
            }
            <div className="col-auto d-none d-lg-flex flex-column justify-content-center align-items-center ps-5" style={{maxHeight:"600px"}}>
              <img id="card-before" src={img.url} alt={img.alt} className="img-fluid img-shadow position-relative" style={{maxHeight:"600px", opacity:"1", transition: "all .25s ease"}} loading="lazy"/>
              <div id="card-after" className="position-absolute" style={{maxWidth:"520px", opacity:"0", transition: "all .25s ease"}}>
                <img src={img2.url} alt={img2.alt} className="img-fluid img-shadow" style={{maxHeight:"600px"}} loading="lazy"/>
                <div className="text-center d-flex justify-content-around mt-4 gap-3">
                  <p className="h4">{text_mockup_left}</p>
                  <p className="h4">{text_mockup_right}</p>
                </div>
                <div className="text-center mt-2">
                  <p className="h5 mb-0">{text_muckup_center}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PremiumCTA/>
      <HowItWorks/>
    </>
  )
}

export const query = graphql`
  fragment PageDataBodyBusinessCardForm on PrismicPageDataBodyBusinessCardForm {
    primary {
      title
      text_premium
      input_label_first_name
      input_label_last_name
      input_label_email
      input_label_phone
      text_agreement {
        richText
      }
      text_test
      text_price_1
      text_price_2
      text_price_3
      text_free_for_7_days
      text_just_a_sec
      text_we_are_generating
      text_card_ready
      title_error
      text_error
      text_mockup_left
      text_mockup_right
      text_muckup_center
      btn_label_generate
      btn_label_buy
      btn_label_download
      btn_label_try_again
      title_how_dbc_work
      text_how_dbc_work_1
      text_how_dbc_work_2
      text_how_dbc_work_3
      img_google_wallet {
        url
        alt
      }
      img_apple_wallet {
        url
        alt
      }
      img {
        url
        alt
      }
      img2 {
        url
        alt
      }
    }
  }
`
