import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

export const TopMenu = ({ topMenu, activeDocMeta }) => {

  const [ifTrialPage,setIfTrialPage] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined'){
      if (window.location.href.indexOf("start-free-trial") > -1) {
        setIfTrialPage(true)
      }   
  }}, []);

  /*const renderedMenuLinks = topMenu
    ? topMenu.menu_links.map((menuLink, index) => (
        <li key={`top-nav-${index}`} className="nav-item">
          <a className="nav-link" href={menuLink.link.url}>{menuLink.label.text}</a>
        </li>
      ))
    : null*/

  return (
    <Navbar collapseOnSelect expand="lg" bg={"white"} variant={"light"} className={"w-100 border-bottom"}>
			<div className="container-fluid py-2 px-3 px-lg-5">
				<a className="navbar-brand" href="/">
					<img src={"/yohn-logo.png"} height="36" width="115" className="d-inline-block align-top" alt="Yohn logo"/>
				</a>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <ul className="navbar-nav ms-auto">
            {/*renderedMenuLinks*/}
            <li>
              <NavDropdown
                id="nav-qr-dropdown"
                title="QR Code Generator"
                menuVariant="light"
              >
                <NavDropdown.Item href="/free-link-to-qr-code-converter/">Free</NavDropdown.Item>
                <NavDropdown.Item href="/qr/">Premium</NavDropdown.Item>
              </NavDropdown>
            </li>
            <li className="nav-item">
			      	<a className="nav-link" href={"/branded-url-shortener/"}>{"URL shortener"}</a>
			      </li>
            <li className="nav-item">
			      	<a className="nav-link" href={"/digital-business-cards/"}>{"Digital business cards"}</a>
			      </li>
            <li className="nav-item">
			      	<a className="nav-link" href={"/#pricing"}>{"Pricing"}</a>
			      </li>
            <li className="nav-item">
              <a className="nav-link" href={"https://yo.hn/30min-meeting"} target="_blank" rel="noreferrer">{"Book a Demo"}</a>
			      </li>
            <li className="nav-item mt-3 mt-lg-0">
							<a className="btn btn-xs btn-primary text-uppercase lightbox-222062268194051" href="/contact-us/">Contact Us</a>
						</li>
          </ul>
        </Navbar.Collapse>
			</div>
    </Navbar>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    type
    lang
    data {
      menu_links {
        label {
          richText
          html
          text
        }
        link {
          id
          url
        }
      }
    }
  }
`
