import * as React from 'react'
import { graphql } from 'gatsby'
import { QRSingleField } from '../components/QR/QRSingleField';
import { QRvCard } from '../components/QR/QRvCard';
import { QRWiFi } from '../components/QR/QRWiFi';
import { QREmail } from '../components/QR/QREmail';
import { QRSMS } from '../components/QR/QRSMS';
import { QRBitcoin } from '../components/QR/QRBitcoin';
import { QRTwitter } from '../components/QR/QRTwitter';

export const QrCodeGenerator = ({slice, index}) => {
  const {title, type, img} = slice.primary
  //console.log("type: ",type)

  let gclid = ""
  let msclkid = ""
  if (typeof window !== 'undefined'){
    const pageUrl = new URL(window.location);
    gclid = pageUrl.searchParams.get("gclid");
    //console.log('gclid: ', gclid);
    msclkid = pageUrl.searchParams.get("msclkid");
    //console.log('msclkid: ', msclkid);
  }

  const isFirst = index === 0;

  return (
    <>
      <div className={"py-6 bg-color-1"} id="generator">
	    	<div className={"container text-center"}>
          {isFirst ? <h1 className="h2 mb-5 text-center" id="title">{title}</h1> : <h2 className="mb-5 text-center">{title}</h2>}
          {img.url &&<img src={img.url} alt={img.alt} className="mb-5" style={{maxHeight:"240px"}}/>}
          {(type == "URL" || type == "Text" || type == "Facebook") &&
            <QRSingleField type={type} gclid={gclid} msclkid={msclkid}/>
          }
          {(type == "vCard") && <QRvCard  gclid={gclid} msclkid={msclkid}/>}
          {(type == "Wifi") && <QRWiFi  gclid={gclid} msclkid={msclkid}/>}
          {(type == "Email") && <QREmail  gclid={gclid} msclkid={msclkid}/>}
          {(type == "SMS") && <QRSMS  gclid={gclid} msclkid={msclkid}/>}
          {(type == "Bitcoin") && <QRBitcoin  gclid={gclid} msclkid={msclkid}/>}
          {(type == "Twitter") && <QRTwitter  gclid={gclid} msclkid={msclkid}/>}
	    	</div>
	    </div>
      {(type == "vCard") && 
        <div className="bg-warning">
          <div className="container d-flex justify-content-center align-items-center p-3">
            <div className="row gx-3 gy-2 gy-lg-3">
              <div className="col-auto d-flex align-items-center mx-auto">
                <p className="mb-0 text-center align-middle h5" >Get up to 72% more leads by switching to digital business cards with lead capture. Book a demo call with Yohn now!</p>
              </div>
              <div className="col-auto mx-auto">
                <a href="https://go.yohn.cloud/30min-meeting" target="_blank" className="btn btn-sm btn-light">BOOK A DEMO</a>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export const query = graphql`
  fragment PageDataBodyQrCodeGenerator on PrismicPageDataBodyQrCodeGenerator {
    primary {
      title
      type
      img {
        url
        alt
      }
    }
  }
`
