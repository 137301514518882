import * as React from 'react'
import { useState } from 'react';
import { useForm } from "react-hook-form";
import {callHook} from '../../utils/utils';
var validator = require("email-validator");
import settings from '../../settings.json';
import {normalizeUrl} from '../../utils/urls';

const ignorePaths = ['/free-link-to-qr-code-converter/','/free-facebook-qr-code-generator/'];
const validateUrl = (url) => {
  // const pageUrl = new URL(window.location);
  // if (ignorePaths.includes(pageUrl.pathname)) {
  //   console.log('skip check')
  //   return true;
  // }
  console.info('check: ', url)
  const normUrl = normalizeUrl(url);
  if (normUrl) return true;
  return false;
}

export const QRSingleField = ({ type, gclid, msclkid }) =>  {
  const [isSend, setSend] = useState(false);
  const [isError, setServerError] = useState(false);
  const [isEmailVisible, setIsEmailVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { register, setValue, setError, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });

  const onShortenClick = () => {
	  setIsEmailVisible(!isEmailVisible);
	};

  const onSubmit = async (data) => {
    //console.log('data: ', data);
    let url = settings.n8n_qr_gen;
    let mode = "no-cors";

    let qrData = {type: type, email: data.email.trim(), data: "", gclid: gclid, msclkid: msclkid}
    
    if (type == "URL" || type == "Facebook") {
      let normUrl = normalizeUrl(data.url)
      // const pageUrl = new URL(window.location);
      // if (ignorePaths.includes(pageUrl.pathname)) {
      //   console.log('skip check')
      //   normUrl = data.url;
      // }
      console.log(`${data.url} => ${normUrl}`)
      // if (normUrl !== data.url) {
      //   setValue('url', normUrl)
      //   setError('url', {
      //     type: 'modify',
      //      // TODO: translate
      //     message: 'We have updated your link to ensure seamless functionality across all mobile platforms.',
      //   })
      //   return
      // }
      qrData.data = normUrl
    } else if (type == "Text") {
      qrData.data = data.text
    }

    //console.log('qrData: ', qrData);
    setLoading(true);
    const r = await callHook({url, uid:'yohn-qr-code', params:qrData, mode});
    //console.log(r);
    setSend(true);
    setLoading(false);
  }

  if (loading) {
    return (
      <div className="text-center">
        <div class="loader mx-auto"></div>
        <h3 className="mt-4">Your QR code is being generated and will be ready in a moment.</h3>
      </div>
    );
  }

  if (isSend) {
    return (
      <div className={"py-5"} id="try">
		    <div className={"container"}>
          <div className="text-center col-12 col-lg-8 mx-auto">
            {!isError && (
            <>
              <h3>Thank You!</h3>
              <h3>We have sent the QR code to your e-mail address.</h3>
            </>
            )}
            {isError && (
            <>
              <h3>An error has occurred</h3>
              <p>Please try again later, server is not available now.</p>
            </>
            )}
            <div className="col-auto mt-4">
              <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{isError ? "Try again" : "Generate another"}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const RenderForm = ({}) => {
    return Object.keys(formFields).map((key, index) => {
      const item = formFields[key];
      //console.log('item: ', item);
      if (isObject(item)) {
        return (<RenderSingle key={index} field={item} title={key} />)
      }
      return (<RenderBlock key={index} num={index} block={item} title={key} />)
    });
  }
console.log('errors: ', errors);
  return (
    <div className="col-12 col-md-9 col-lg-7 mx-auto row align-items-center">
      <form id={type.toLowerCase()} className="text-center col" onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          {(type == "URL") &&
            <input
              placeholder="Your link here"
              className={'form-control' + " " + ((errors.url && errors.url.type === "validate") && "is-invalid")}
              maxLength="2000"
              {...register('url', {required: true, validate: (input) => validateUrl(input)})}
            />
          }
          {(type == "Text") &&
            <textarea
              placeholder="Your text here"
              className={'form-control' + " " + (errors.full && "is-invalid")}
              rows="3"
              maxLength="2000"
              {...register('text', {required: true})}
            />
          }
          {(type == "Facebook") &&
            <input
              placeholder="Enter your Facebook URL"
              className={'form-control' + " " + ((errors.url && errors.url.type === "validate") && "is-invalid")}
              maxLength="2000"
              {...register('url', {required: true, validate: (input) => validateUrl(input)})}
            />
          }
          <div className="invalid-feedback" wfd-invisible="true">{'Invalid url!'}</div>
          {(errors.url && errors.url.type === 'modify') && <div class="mt-3 alert alert-success" role="alert">{errors.url.message}</div>}
        </div>
        <div className={"mt-4" + " " + (!isEmailVisible && "d-none")}>
          <input
            placeholder="Your email address"
            className={'form-control' + " " + (errors.email && isSubmitted ? "is-invalid" : "")}
            {...register('email', {required: true, validate: (input) => validator.validate(input)})}
          />
          {errors.email && isSubmitted && <div className="invalid-feedback">{'Invalid email address'}</div>}
          <div className="text-center mt-3 fs-0875">Type in or paste your email address here and we will send your QR-code to you right away.</div>
        </div>
        <div className={"d-flex justify-content-center mt-4" + " " + (!isEmailVisible ? "d-none" : "")}>
          <label className="form-check-label" for="flexCheckChecked"><input className="form-check-input me-2" type="checkbox" value="" id="flexCheckChecked" required/><span className="fs-0875">I agree to the <a href="/terms/">Terms of Service</a>, <a href="/privacy-policy/">Privacy Policy</a> and Use of Cookies.</span></label>
        </div>
        <input type="submit" value="Get QR code" className={"mt-4 btn btn-sm btn-primary text-uppercase" + " " + (!isEmailVisible ? "d-none" : "")}/>
      </form>
      <div className={"text-center col-auto" + " " + (isEmailVisible ? "d-none" : "")}>
        <button onClick={onShortenClick} className={"btn btn-sm btn-primary align-center text-uppercase"}>Get QR code</button>
      </div>
    </div>
  );
}