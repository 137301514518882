import * as React from 'react'
import { useState } from 'react';
import { graphql } from 'gatsby'
import { useForm } from "react-hook-form";
import {callHook} from '../utils/utils';
var validator = require("email-validator");
import settings from '../settings.json';
import sha1 from 'crypto-js/sha1';
import Base64 from 'crypto-js/enc-base64';
import Hex from 'crypto-js/enc-hex';

export const ContactForm2 = ({ slice, index }) => {
  const {title, email, phone_num, address, btn_label, btn_link} = slice.primary

  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });
  
  const isFirst = index === 0;

  if (typeof window !== 'undefined'){
    const pageUrl = window.location;
    //console.log('pageUrl: ', pageUrl);
  }  

  const onSubmit = async (data) => {
    // data = Object.assign({id: "yohn-contact-us"}, data);
    // console.log('data: ', data);
    const hashDigest = sha1(data.email);
    const emailSHA1 = Hex.stringify(hashDigest);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'SHA1_USER_EMAIL': emailSHA1});

    var visitorId
  	var _paq = window._paq || [];
		_paq.push([ function() { visitorId = this.getVisitorId(); }]);
    data.visitorId = visitorId

    const url = settings.forms_webhook;

    setLoading(true);
    const r = await callHook({url, uid:'yohn-contact-us', params:data});
    //console.log(r);
    if (r && r.success === true) {
      setError(false);
    } else {
      setError(true);
    }
    setSend(true);
    setLoading(false);
  }

  return (
    <div className="py-6 bg-light">
			<div className="container">
        <div className="col-12 col-xl-9 mx-auto">
          {isFirst ? <h1 className="h2 text-center mb-5">{title}</h1> : <h2 className="text-center mb-5">{title}</h2>}
        </div>
        <div className="row g-4">
          <div className="col-12 col-lg-5">
            <div className="bg-white p-4 rounded-4">
              <p><a href={`mailto:${email}`} className="h4 text-decoration-none">{email}</a></p>
              <p><a href={`tel:${phone_num}`} className="h4 text-decoration-none">{phone_num}</a></p>
              <p><span className="">{address}</span></p>
              {(btn_label && btn_link) &&<a href={btn_link.url} className={"btn text-uppercase btn-primary mt-2"}>{btn_label}</a>}
            </div>
          </div>
          <div className="col-12 col-lg-7 mx-auto">
            {!isSend ?
              <>
                {!isLoading ?
                  <form id="yohn-contact-us" className="text-center" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <input
                        id="email"
                        placeholder="Your email address"
                        className={'form-control' + " " + (errors.email && isSubmitted ? "is-invalid" : "")}
                        {...register('email', {required: true, validate: (input) => validator.validate(input)})}
                      />
                      {errors.email && isSubmitted && <div className="invalid-feedback">{'Invalid email address'}</div>}
                    </div>
                    <div className={"mt-4"}>
                      <input
                        id="phone"
                        placeholder="+0 (000) 000-0000"
                        type="tel"
                        className={"form-control"}
                        {...register('phone')}
                      />
                    </div>
                    <div className={"mt-4"}>
                      <textarea
                        id="text"
                        placeholder="Type your question"
                        type="text"
                        rows="5"
                        className={"form-control" + " " + (errors.text && isSubmitted ? "is-invalid" : "")}
                        {...register('text', {required: true})}
                      />
                      {errors.text && isSubmitted && <div className="invalid-feedback">{'Required!'}</div>}
                    </div>
                    <input type="submit" value="Submit" className={"mt-4 btn btn-sm btn-primary text-uppercase"}/>
                  </form>
                :
                  <div className="text-center">
                    <div className="loader mx-auto"></div>
                  </div>
                }
              </>
            :
              <div className="text-center">
                {!isError ?
                  <>
                    <h3>Thank You!</h3>
                    <p>We will contact you shortly.</p>
                  </>
                :
                  <>
                    <h3>An error has occurred</h3>
                    <p>Please try again later, server is not available now.</p>
                  </>
                }
                <div className="col-auto">
                  <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{isError ? "Try again" : "Ask another question"}</button>
                </div>
              </div>
            }
          </div>
        </div>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment PageDataBodyContactForm2 on PrismicPageDataBodyContactForm2 {
    primary {
      title
      email
      phone_num
      address
      btn_label
      btn_link {
        url
      }
    }
  }
`
