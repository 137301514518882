import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const CreateWalletCard = ({ slice, index }) => {
  const [token, setToken] = React.useState();
  const {title, img, features} = slice.primary
  const isFirst = index === 0;

  React.useEffect(() => {
    console.log('did mount');
    if (typeof window !== 'undefined'){
      const params = new URLSearchParams(window.location.search.substring(1));
      let _token = params.get("token");
      console.log("token", _token)
      setToken(_token);
    } 
  }, []);


  return (
    <div className={"py-6 bg-color-1"} id="generator">
		  <div className={"container text-center"}>
        {isFirst ? <h1 className="h2 mb-5 text-center" id="title">{title}</h1> : <h2 className="mb-5 text-center">{title}</h2>}
        {token && (
          <div>
            <div className="text-center col-12 col-lg-6 p-4 mx-auto bg-white rounded-4" style={{border:"solid 4px #c5f1df"}}>
              <div className="mb-4">
                <a href={"https://go.yohn.cloud/create-wallet-card?token=" + token} target="_blank" rel='noreferrer'><img src="https://images.prismic.io/colibri-live/bc62e3a7-cf93-428a-86e1-85e4834a0646_US-UK_Add_to_Apple_Wallet.png" style={{margin:"4px 8px"}} alt="Apple   wallet"/></a>
                <a href={"https://go.yohn.cloud/create-wallet-card?token=" + token} target="_blank" rel='noreferrer'><img src="https://images.prismic.io/colibri-live/763224d2-5f17-44cd-a9b4-ed5ae4b8aa88_enUS_add_to_google_wallet_add-wallet-badge.png" style={{margin:"4px  8px"}} alt="Google wallet"/></a>
              </div>
              <div className="mt-4" style={{width:"fit-content",margin:"auto",textAlign:"left"}}>
                <PrismicRichText field={features.richText} />
              </div>
              <div className="mx-auto" style={{maxWidth:"320px"}}>
                <a href={"https://go.yohn.cloud/create-wallet-card?token=" + token} target="_blank" rel='noreferrer'>
                  <img src="https://images.prismic.io/colibri-live/5fd70537-a31f-4636-82c5-a95da461ae16_wallet-card-email-2.png" className="img-fluid" alt="Business Card Wallet"/>
                </a>
              </div>
            </div>
          </div>
        )}
        {!token && (
          <div>
            <div class="loader mx-auto"></div>
            <h3 className="mt-4">Loading</h3>
            <p>Waiting for identifier</p>
          </div>
        )}
		  </div>
	  </div>
  )
}

export const query = graphql`
  fragment PageDataBodyCreateWalletCard on PrismicPageDataBodyCreateWalletCard {
    primary {
      title
      img {
        url
      }
      features {
        richText
      }
    }
  }
`
