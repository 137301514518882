import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const FeaturesNumList = ({ slice }) => {
  const {title} = slice.primary

  const features = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-12 col-lg-6 mx-auto">
        <div className="bg-white rounded h-100 overflow-hidden shadow">
          <div className="p-5 h-100">
            <div className="h-100 d-flex align-items-center">
              <div className="d-flex align-items-center">
                <p className="display-2 fw-medium mb-0 me-4">{index+1}</p>
              </div>
              <div>
				        <h4 className="">{item.title}</h4>
                <div className="no-mb-richtext">
                  <PrismicRichText field={item.text.richText} className="mb-0"/>
                </div>
              </div>
            </div>
          </div>
        </div>
			</div>
    )
  })

  return (
    <>
      <div  className="bg-white py-6" id="features">
		  	<div className="container">
          <h2 className="text-center mb-5">{title}</h2>
		  		<div className="row g-5">
            {features}
		  		</div>
		  	</div>
        {/*Pricing*/}
        {/*<div className="container pt-6">
          <h2 className="text-center mb-5">{"Pricing"}</h2>
		  		<div className="row g-5">
            <div className="col-12 col-lg-6 mx-auto">
              <div className="bg-light p-5">
                <div className="d-flex">
                  <div>
                    <h4 className="">{"One-off purchase"}</h4>
                    <p className="display-4 fw-semibold mb-0 me-4">{"1,00$"}</p>
                  </div>
                  <ul>
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Lorem ipsum dolor sit amet</li>
                  </ul>
                </div>
                <a href="#" className="mt-4 btn btn-sm btn-secondary text-uppercase w-100">Go to Shop</a>
              </div>
            </div>
            <div className="col-12 col-lg-6 mx-auto">
              <div className="bg-color-1 p-5">
                <div className="d-flex">
                  <div>
                    <h4 className="">{"Subscription"}</h4>
                    <p className="display-4 fw-semibold mb-0 me-4">{"1,99$"}</p>
                  </div>
                  <ul>
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Lorem ipsum dolor sit amet</li>
                  </ul>
                </div>
                <a href="#" className="mt-4 btn btn-sm btn-primary text-uppercase w-100">Subscribe Now</a>
              </div>
            </div>
		  		</div>
		  	</div>*/}
		  </div>
    </>
  )
}

export const query = graphql`
  fragment PageDataBodyFeaturesNumList on PrismicPageDataBodyFeaturesNumList {
    primary {
      title
    }
    items {
      title
      text {
        richText
      }
    }
  }
`
